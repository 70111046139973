@import "variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "bootswatch.scss";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-bootstrap-typeahead/css/Typeahead.bs5.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mt--7 {
  margin-top: -7rem;
}

.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.w-10 {
    width: 10%;
}

thead tr th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white !important;
}
